import { mergeEnvVars } from './services/Utils';

export const isProd = (process.env.NODE_ENV === 'production');
const env = mergeEnvVars(process.env.NODE_ENV, {
  isProd,
  cmsURL: 'https://www.teachizy.fr/wp-json/wp/v2',
  production: {
    rootURL: 'https://teachizy.fr',
    rootHost: 'teachizy.fr',
    appURL: 'https://app.teachizy.fr',
    apiDomain: 'https://api.teachizy.fr',
    apiResourcesURL: 'https://api.teachizy.fr/storage',
    apiURL: 'https://api.teachizy.fr/api/v1',
    stripePK: 'pk_live_bIcP8W3KD9A2l68B6q1RXFZz00B7qZZlDt',
    stripeConnectID: 'ca_H22upTmbMHeHwFeUk2h4M1jDlslBVA6r',
    stripeAIPrices: {
      PACK_S: 'price_1MZ7MFDTshtGyDUGS6ko3iBU',
      PACK_M: 'price_1MZ7MFDTshtGyDUGcknAWW2V',
      PACK_L: 'price_1MZ7MFDTshtGyDUGIwXkBBWX',
    },
    turnstileKey: '0x4AAAAAAAjq66DVgEwhQVZS',
    matomoHost: 'https://teachizy.matomo.cloud/',
    matomoSiteId: 2,
  },
  preprod: {
    rootURL: 'https://preprod.teachizy.fr',
    rootHost: 'preprod.teachizy.fr',
    appURL: 'https://app.preprod.teachizy.fr',
    apiDomain: 'https://api.preprod.teachizy.fr',
    apiResourcesURL: 'https://api.preprod.teachizy.fr/storage',
    apiURL: 'https://api.preprod.teachizy.fr/api/v1',
    stripePK: 'pk_test_EFllbbqSbw6gz2KSNeOyI7aV00iC3Ir17c',
    stripeConnectID: 'ca_H22umHcQtMTV34EXhW1DXLlbovE7jdnV',
    stripeAIPrices: {
      PACK_S: 'price_1MXRbHDTshtGyDUGGeKNAYMZ',
      PACK_M: 'price_1MXRbHDTshtGyDUGGWwAALba',
      PACK_L: 'price_1MXRbHDTshtGyDUGcR62J7HF',
    },
    turnstileKey: '0x4AAAAAAAjq6u2yuz4SmtHP',
    matomoHost: 'https://teachizy.matomo.cloud/',
    matomoSiteId: 3,
  },
  staging: {
    rootURL: 'https://staging.teachizy.fr',
    rootHost: 'staging.teachizy.fr',
    appURL: 'https://app.staging.teachizy.fr',
    apiDomain: 'https://api-staging.teachizy.fr',
    apiResourcesURL: 'https://api-staging.teachizy.fr/storage',
    apiURL: 'https://api-staging.teachizy.fr/api/v1',
    stripePK: 'pk_test_EFllbbqSbw6gz2KSNeOyI7aV00iC3Ir17c',
    stripeConnectID: 'ca_H22umHcQtMTV34EXhW1DXLlbovE7jdnV',
    stripeAIPrices: {
      PACK_S: 'price_1MXRbHDTshtGyDUGGeKNAYMZ',
      PACK_M: 'price_1MXRbHDTshtGyDUGGWwAALba',
      PACK_L: 'price_1MXRbHDTshtGyDUGcR62J7HF',
    },
    turnstileKey: '0x4AAAAAAAjq56_Juo2RaAgq',
    matomoHost: 'https://teachizy.matomo.cloud/',
    matomoSiteId: 3,
  },
  development: {
    rootURL: 'http://teachizy.localhost',
    rootHost: 'teachizy.localhost',
    appURL: 'http://app.teachizy.localhost',
    apiDomain: 'http://api.teachizy.localhost',
    apiResourcesURL: 'http://api.teachizy.localhost/storage',
    apiURL: 'http://api.teachizy.localhost/api/v1',
    stripePK: 'pk_test_EFllbbqSbw6gz2KSNeOyI7aV00iC3Ir17c',
    stripeConnectID: 'ca_H22umHcQtMTV34EXhW1DXLlbovE7jdnV',
    stripeAIPrices: {
      PACK_S: 'price_1MXRbHDTshtGyDUGGeKNAYMZ',
      PACK_M: 'price_1MXRbHDTshtGyDUGGWwAALba',
      PACK_L: 'price_1MXRbHDTshtGyDUGcR62J7HF',
    },
    // for testing: https://developers.cloudflare.com/turnstile/troubleshooting/testing/
    turnstileKey: '1x00000000000000000000AA',
    matomoHost: 'https://teachizy.matomo.cloud/',
    matomoSiteId: 3,
  },
});

const contextRoot = env.appURL.includes(window.location.hostname)
  ? '@app'
  : '@school';

env.contextRoot = contextRoot;

export {
  env,
  contextRoot,
};
